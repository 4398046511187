import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import { Container, Row, Col } from '../components/Container'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header/subpage'
import { PageContent } from '../components/Page'

class PageTemplate extends Component {
    render() {
        const currentPage = this.props.data.wordpressPage
        const headerImage = currentPage.acf.header_image ? currentPage.acf.header_image.localFile.childImageSharp.fluid : this.props.data.wordpressAcfOptions.options.header_image.localFile.childImageSharp.fluid
        const headerTitle = currentPage.acf.header_content && currentPage.acf.header_content.title ? currentPage.acf.header_content.title : currentPage.title
        const headerText = currentPage.acf.header_content && currentPage.acf.header_content.text ? currentPage.acf.header_content.text : this.props.data.wordpressAcfOptions.options.header_content.text      
        const subTitle = currentPage.acf.sub_title
        const intro = currentPage.acf.introduction_content
        const content = currentPage.acf.content
        const image = currentPage.acf.image && currentPage.acf.image.localFile.childImageSharp.fluid
        const formData = currentPage.acf.form_group
        return (
          <Layout 
            title={currentPage.yoast.title ? currentPage.yoast.title : currentPage.title}
            seo={currentPage.yoast}
            location={"/" + currentPage.slug + "/"}
            padding="80vh 0 0 0"
          >           
            <article>
              <Header 
                image={headerImage} 
                title={headerTitle}
                text={headerText}
              />
              <Container>
                <Row align="flex-start" justify="space-between">
                  <Col 
                    xxl={{ 
                      width: '85%', 
                    }} 
                    className="pageContent"
                  >
                    <PageContent 
                      title={currentPage.title}
                      subTitle={subTitle}
                      intro={intro}
                      image={image}
                      content={content}
                      form={formData}
                    />
                  </Col>
                  <SidebarOuter 
                    xl={{
                      width: '15%'
                    }}
                  >
                    <Sidebar />                                        
                  </SidebarOuter>
                </Row>              
              </Container>
            </article>
          </Layout>
        )
    }
}

export default PageTemplate

const SidebarOuter = styled(Col)`
  position: relative;
  display: none;
  @media (min-width: 1400px) {
    display: inline-block;
  }
`

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      yoast {
        title
        metadesc
        opengraph_title
        opengraph_description
        opengraph_image {
          localFile {
            publicURL
          }
        }
        twitter_title
        twitter_description
        twitter_image {
          localFile {
            publicURL
          }
        }
      }
      acf {
        header_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header_content {
          title
          text
        }
        sub_title
        introduction_content
        content
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 380, maxHeight: 380) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        form_group {
          #title
          wordpress_fields {
            type
            name
            width
            required
            button_label
            #label
            placeholder
            input_type
            maxlength
            message
            #class
            #wordpress_id
            # select_options {
            #   option
            #   default
            #   disabled
            # }
          }
        }        
      }
    }
    wordpressAcfOptions {
      options {
        header_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header_content {
          text
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`