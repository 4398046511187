import React from 'react'
import styled from 'styled-components'
import { cover, rem } from 'polished'
import Img from 'gatsby-image'
import Tilt from 'react-tilt'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'

import Container from '../Container'
import Title from '../Title'
import { ButtonLink } from '../Button'

import { DarkColour, AltFont } from '../../utils/variables'

const Header = (props) => (
  <Outer>
    <Overlay />
    <Img fluid={props.image} />
    <Controller>
      <Scene
        duration={400}
        offset={0}
        triggerElement={null}
        // reverse={false}
        // indicators={true}
      >
        {(progress) => (
          <Tween
            to={{
              transform: 'translate(0,-70%)',
              opacity: '0'
            }}
            totalProgress={progress}
            paused
          >
            <Inner>
              <Container>
                <Tilt className="tilt" options={{ max: 0.5, scale: 1.01 }} >
                  {props.title && <PageTitle dangerouslySetInnerHTML={{ __html: props.title }} />}
                  {props.text && <HeaderText dangerouslySetInnerHTML={{ __html: props.text }} />}
                </Tilt>
                {props.returnHome &&
                  <ReturnButton to="/" primary>Return Home</ReturnButton>
                } 
              </Container>
            </Inner> 
          </Tween>
        )}
      </Scene>
    </Controller>  
              
  </Outer>
)
 
export default Header

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-width: 100vw;
  min-height: 100vh;
  background: ${DarkColour};
  .gatsby-image-wrapper {
    ${cover()};
    min-height: 100vh;
    z-index: 10;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  z-index: 30;
  @media (min-width: 768px) {
    top: 140px;
  }
  @media (min-width: 992px) {
    top: 160px;
  }
  @media (min-width: 1200px) {
    top: 220px;
  }
  @media (min-width: 1600px) {
    top: 260px;
  }
`

const Overlay = styled.div`
  ${cover()};
  background-color: ${DarkColour};
  opacity: 0.8;
  z-index: 20;
`

const PageTitle = styled(Title)`  
  margin-bottom: ${rem('30px')};
  position: relative;
  font-family: ${AltFont};
  font-style: italic;
  letter-spacing: -0.05em;
  font-size: ${rem('40px')};
  @media (min-width: 768px) {
    font-size: ${rem('50px')};
  }  
  @media (min-width: 992px) {
    margin-bottom: ${rem('60px')};
  }  
  @media (min-width: 1200px) {
    font-size: ${rem('70px')};
    padding-bottom: ${rem('60px')};
    &:after {
      content: '';
      display: inline-block;
      width: 300px;
      height: 4px;
      background-color: #fff;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
`

const HeaderText = styled.p`    
  @media (min-width: 768px) {
    font-size: ${rem('24px')};
  }
  @media (min-width: 992px) {
    font-size: ${rem('30px')}; 
  }
  @media (min-width: 1400px) {
    max-width: 70%;
  }
  @media (min-width: 1600px) {
    font-size: ${rem('36px')};    
  }  
`

const ReturnButton = styled(ButtonLink)`
  margin-top: ${rem('90px')};
`