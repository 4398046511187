import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { rem, rgba } from 'polished'
import domToReact from 'html-react-parser'
import SVG from 'react-inlinesvg'

import { H2 } from '../Title'
import Form from '../Form'
import { Row } from '../Container'
// import { Icons } from '../Homepage/Footer/details'
import { FooterIconList, FooterIconCircle } from '../Homepage/Footer/details'

import { MainColour, Semi, AltColour, AltFont, PDBold } from '../../utils/variables'

import IconPhone from '../../images/footer-phone.svg'
import IconAddress from '../../images/footer-address.svg'
import IconEmail from '../../images/footer-email.svg'

export const PageContent = (props) => (
  <Content>
    {props.subTitle && <SubTitle dangerouslySetInnerHTML={{ __html: props.subTitle }} />}
    {props.intro && 
      <>
        <Intro dangerouslySetInnerHTML={{ __html: props.intro }} />
        <hr />
      </>
    }
    {props.image && 
      <Image>
        <Img fluid={props.image} />
      </Image> 
    }                     
    {props.content && domToReact(props.content)}  
    {props.form && 
      <section>
      <StaticQuery
        query={graphql`
          query contactPageQuery {
            wordpressAcfOptions {
              options {
                general {
                  address
                  post_code
                  phone_number
                  email_address
                }
              }
            }
          }
        `}
        render={data =>
          <Row align="center" justify="center">
            <FooterIconList style={{ margin: '0 auto ' + rem('90px') }}>
              <FooterIconCircle text={data.wordpressAcfOptions.options.general.phone_number}>
                <a href={"tel:" + data.wordpressAcfOptions.options.general.phone_number} aria-label={"Call us on: " + data.wordpressAcfOptions.options.general.phone_number}>
                  <SVG src={IconPhone} />
                </a>
              </FooterIconCircle>
              <FooterIconCircle text={data.wordpressAcfOptions.options.general.address + ' ' + data.wordpressAcfOptions.options.general.post_code} hidden768>
                <SVG src={IconAddress} />
              </FooterIconCircle>
              <FooterIconCircle text={data.wordpressAcfOptions.options.general.email_address}>
                <a href={"mailto:" + data.wordpressAcfOptions.options.general.email_address} aria-label={"Email us at: " + data.wordpressAcfOptions.options.general.email_address}>
                  <SVG src={IconEmail} />
                </a>
              </FooterIconCircle>
            </FooterIconList>
          </Row>
          
          }
        />
        <Form data={props.form} name={props.title} />
      </section>
    }                   
  </Content>
)

export default PageContent

const Content = styled.section`
  background-color: ${MainColour};
  padding: ${rem('45px')};
  hr {
    background-color: ${rgba('#fff', 0.1)};
    margin: ${rem('60px')} 0;
  }
  p {
    line-height: 1.8;
  }
  blockquote {
    text-align: center;
    p {
      font-size: ${rem('32px')};
      font-weight: ${Semi};
      font-style: italic;
      line-height: 1.5;
      &:before, &:after {
        color: ${AltColour};
      }
      &:before {
        content: '“';
      }
      &:after {
        content: '”';
      }
      span {
        font-family: ${AltFont};
        font-weight: ${PDBold};
      }
    }    
  }
  @media (min-width: 576px) {
    padding: ${rem('60px')};
  }
  @media (min-width: 768px) {
    padding: ${rem('90px')};
  }
  @media (min-width: 1200px) {
    padding: ${rem('120px')};
  }
  @media (min-width: 1600px) {
     hr {
      margin: ${rem('90px')} 0;
    }
  }
`

const SubTitle = styled(H2)`
  position: relative;
  padding-bottom: ${rem('45px')};
  font-family: ${AltFont};
  font-style: italic;
  letter-spacing: -0.05em;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120px;
    height: 4px;
    background-color: #fff;
  }
  @media (min-width: 1600px) {
    font-size: ${rem('45px')};
    margin-bottom: ${rem('60px')};
    padding-bottom: ${rem('60px')};
  }
`

const Intro = styled.p`
  font-family: ${AltFont};
  font-size: ${rem('20px')};  
  @media (min-width: 1600px) {
    font-size: ${rem('24px')};
  }
`

const Image = styled.div`  
  width: 100%;
  height: auto;
  margin: 0 0 ${rem('45px')} 0;
  @media (min-width: 992px) {
    float: left;
    width: 270px;
    height: 270px;
    margin: ${rem('10px')} ${rem('60px')} ${rem('20px')} 0;
  }
  @media (min-width: 1200px) {
    width: 210px;
    height: 210px;
  }
  @media (min-width: 1600px) {
    width: 380px;
    height: 380px;
    margin: ${rem('10px')} ${rem('90px')} ${rem('50px')} 0;
  }
`