import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { rem } from 'polished'
import SVG from 'react-inlinesvg'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'

import MobileMenu from '../Menu/lightbox'
import { LinkAnimation } from '../Menu'

import { AltFont, PDBold, AltColour } from '../../utils/variables'

import IconPhone from '../../images/footer-phone.svg'
import IconEmail from '../../images/footer-email.svg'
import Logo from '../../images/digital-litigation-partners-icon.svg'
import Arrow from '../../images/banner-arrow-down.svg'
import Facebook from '../../images/footer-facebook.svg'
import Twitter from '../../images/footer-twitter.svg'
import Instagram from '../../images/footer-instagram.svg'
import LinkedIn from '../../images/footer-linkedin.svg'

const StandardSidebar = () => (
  <Controller>
    <Scene
      duration={400}
      offset={200}
      triggerElement={null}
    // indicators={true}
    >
      {(progress) => (
        <Tween
          to={{
            transform: 'translate(0,-50%)',
            opacity: '0'
          }}
          totalProgress={progress}
          paused
        >
          <ScrollIndicator>
            <span className="text">Learn More</span>
            <SVG src={Arrow} />
          </ScrollIndicator>
        </Tween>
      )}
    </Scene>
    <Scene
      triggerHook="onLeave"
      duration={300}
      offset={650}
      triggerElement={null}
    // indicators={true}
    // pin={true}
    >
      {(sidebarProgress) => (
        <Tween
          from={{
            opacity: '0',
            top: '180px'
          }}
          to={{
            opacity: '1',
            top: '45px'
          }}
          totalProgress={sidebarProgress}
          paused
        >
          <SidebarContainer>
            <SidebarContent />
          </SidebarContainer>
        </Tween>
      )}
    </Scene>
  </Controller>
)

export default StandardSidebar

const SidebarContainer = styled.div`
  position: fixed;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  max-width: 180px;
  @media (min-width: 1600px) {
    max-width: 200px;
  }
`

const ScrollIndicator = styled.div`
  text-align: center;
  span {
    display: block;
    &.text {
      font-family: ${AltFont};
      color: #fff;
      font-size: ${rem('24px')};
      font-style: italic;
      font-weight: ${PDBold};
      margin: ${rem('30px')} 0;
    }
    &.isvg {
      svg {
        animation: bounceArrow 2s infinite;
      }
    }
  }
`

const SidebarContent = () => (
  <StaticQuery
    query={graphql`
      query SidebarQuery {
        allWordpressWpApiMenusMenusItems(
          filter: {
            wordpress_id: {
              eq: 2
            }
          }
        ) {
          edges {
            node {
              slug
              items {
                wordpress_id
                title
                target
                classes
                url
                wordpress_children {
                  wordpress_id
                  title
                  target
                  classes
                  url
                }
              }
            }
          }
        }
        wordpressAcfOptions {
          options {
            general {
              phone_number
              email_address
              facebook
              twitter
              linkedin
              instagram
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <MobileMenu menu={data.allWordpressWpApiMenusMenusItems} contact={data.wordpressAcfOptions.options.general} sidebar />
        <SidebarLogo>
          <AniLink
            to="/"
            cover
            bg={LinkAnimation}
            direction="right"
            duration={2}
          >
            <SVG src={Logo} />
          </AniLink>
        </SidebarLogo>        
        <Icons data={data.wordpressAcfOptions.options.general} />
        <Social data={data.wordpressAcfOptions.options.general} />
      </>
    )}
  />
)

const SidebarLogo = styled.div`
  max-width: 95px;
  opacity: 0.8;
  margin: ${rem('45px')} auto 0;
  transition: all 0.3s ease;
  flex: 0 0 100%;
  &:hover {
    opacity: 1;
  }
`

const Icons = (props) => (
  <IconList>
    <IconCircle text={props.data.phone_number}>
      <a href={"tel:" + props.data.phone_number}>
        <SVG src={IconPhone} />
      </a>
    </IconCircle>
    <IconCircle text={props.data.email_address}>
      <a href={"mailto:" + props.data.email_address}>
        <SVG src={IconEmail} />
      </a>
    </IconCircle>
  </IconList>
)

const IconList = styled.ul`
  display: none;
  width: auto;
  margin: ${rem('15px')} 0 0;
  flex: 0 0 100%;
  @media (min-height: 765px) {
    display: inline-block;
  }
`

const IconCircle = styled.li`
  height: 140px;
  width: 140px;
  border-radius: 140px;
  background-color: ${AltColour};
  position: relative;
  margin: 0 auto ${rem('65px')} auto;
  &:last-child {
    margin: 0 auto;
  }
  &:before {
    content: '';
    height: 140px;
    width: 140px;
    border-radius: 140px;
    border: 2px solid ${AltColour};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all 0.3s ease;
  }
  &:after {
    content: '${props => props.text}';
    font-size: ${rem('18px')};
    font-family: ${AltFont};
    font-weight: ${PDBold};
    line-height: 1.2;
    font-style: italic;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translate(-50%,0);
    width: 160%;
    transition: all 0.3s ease;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all 0.3s ease;
    width: 50%;
  }
  &:hover {
    svg {
      transform: translate(-50%,-50%) scale(1.05);
    }  
    &:before {
      transform: translate(-50%,-50%) scale(1.1);
    }  
  }
`

const Social = (props) => (
  <SocialIcons>
    <li><a href={props.data.facebook} target="_blank" rel="noopener noreferrer"><SVG src={Facebook} /></a></li>
    <li><a href={props.data.twitter} target="_blank" rel="noopener noreferrer"><SVG src={Twitter} /></a></li>
    <li><a href={props.data.instagram} target="_blank" rel="noopener noreferrer"><SVG src={Instagram} /></a></li>
    <li><a href={props.data.linkedin} target="_blank" rel="noopener noreferrer"><SVG src={LinkedIn} /></a></li>
  </SocialIcons>
)

const SocialIcons = styled.ul`
  
  display: none;
  width: auto;
  margin: ${rem('85px')} auto;
  * {
    line-height: 0;
    vertical-align: top;
  }
  li {
    float: left;
    margin-right: ${rem('10px')};
    &:last-child {
      margin: 0;
    }
  }
  svg {
    max-width: 24px;
  }
  @media (min-height: 850px) {
    display: inline-block;
  }
`